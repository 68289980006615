import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { useEffect } from 'react';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11'
import './assets/base.scss';
import configureStore from './config/configureStore';
import App from './App';

export const store = configureStore()
const queryClient = new QueryClient()
const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement);

const QueryClientWrapper = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

const renderApp = (Component) => {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientWrapper>
          <Component />
        </QueryClientWrapper>
      </BrowserRouter>
    </Provider>
  );
};

const Main = () => {
  useEffect(() => {
    serviceWorker.unregister()
  }, [])

  return <App />
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./pages/Main', () => {
    const NextApp = require('./pages/Main').default;
    renderApp(NextApp)
  });
}

