import cx from 'classnames';
import AppMain from 'Layout/AppMain';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { withRouter } from 'react-router-dom';

const Main = (props) => {
    const closedSmallerSidebar = false;
    const colorScheme = useSelector((state) => state.ThemeOptions.colorScheme);
    const enableFixedHeader = useSelector((state) => state.ThemeOptions.enableFixedHeader);
    const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);
    const enableFixedFooter = useSelector((state) => state.ThemeOptions.enableFixedFooter);
    const enableFixedSidebar = useSelector((state) => state.ThemeOptions.enableFixedSidebar);
    const enableClosedSidebar = useSelector((state) => state.ThemeOptions.enableClosedSidebar);
    const enablePageTabsAlt = useSelector((state) => state.ThemeOptions.enablePageTabsAlt);

    // Using useResizeDetector hook
    const { width, ref } = useResizeDetector();

    return (
        <Fragment>
            <div
                ref={ref} // Attach the resize detector to this container
                className={cx(
                    'app-container app-theme-' + colorScheme,
                    { 'fixed-header': enableFixedHeader },
                    { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                    { 'fixed-footer': enableFixedFooter },
                    { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                    { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                    { 'sidebar-mobile-open': enableMobileMenu },
                    { 'body-tabs-shadow-btn': enablePageTabsAlt }
                )}
            >
                <AppMain />
            </div>
        </Fragment>
    );
};

export default withRouter(Main);
