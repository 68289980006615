import cx from 'classnames';
import HeaderLogo from 'components/Header/AppLogo';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MegaMenu from './components/MegaMenu';
import UserBox from './components/UserBox';
import { Helmet } from 'react-helmet-async';

const headerStyles = {
	base: {
		transition: 'opacity 1.5s ease-in-out',
		opacity: 0,
	},
	appear: {
		opacity: 1,
	},
};

class Header extends React.Component {
	render() {
		let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = this.props;

		return (
			<React.Fragment>
				<TransitionGroup component={null}>
					<CSSTransition
						timeout={{ appear: 1500, enter: 0, exit: 0 }}
						appear
					>
						<div
							style={{
								...headerStyles.base,
								...(headerStyles.appear),
							}}
							className={cx(
								'app-header',
								headerBackgroundColor,
								{ 'header-shadow': enableHeaderShadow }
							)}
						>
							<HeaderLogo />
							<div
								className={cx('app-header__content', {
									'header-mobile-open': enableMobileMenuSmall,
								})}
							>
								<div className="app-header-left">
									<MegaMenu />
								</div>
								<div className="app-header-right">
									<UserBox />
								</div>
								<Helmet>
									<script
										src="https://api.skolon.com/v1/plugins/menu/button.js"
										type="text/javascript"
									/>
								</Helmet>
							</div>
							<div className="la-skolon-button-wrapper">
								<div className="skolon-menu-button" data-shadow="false"></div>
							</div>
						</div>
					</CSSTransition>
				</TransitionGroup>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
	closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
	headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
