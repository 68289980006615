import React from 'react'
import { connect } from 'react-redux'
import { Sling as Hamburger } from 'hamburger-react';

import { setEnableMobileMenu, setEnableMobileMenuSmall } from '../../store/reducers/ThemeOptions';
import UserBox from '../AppHeader/components/UserBox';

class AppMobileMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			mobile: false,
			activeSecondaryMenuMobile: false
		};
	}

	toggleMobileSidebar = () => {
		let { enableMobileMenu, setEnableMobileMenu } = this.props;
		setEnableMobileMenu(!enableMobileMenu);
		this.setState({ active: !this.state.active });
	};

	render() {
		return (
			<React.Fragment>
				<div className="app-header__mobile-menu">
					<div onClick={this.toggleMobileSidebar}>
						<Hamburger toggled={this.state.active} toggle={this.toggleMobileSidebar} />
					</div>
				</div>

				<div className="app-header__menu">
					<UserBox />
				</div>
			</React.Fragment>
		);
	}
}

export default connect(
	state => ({
		enableMobileMenu: state.ThemeOptions.enableMobileMenu,
		enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
	}),
	{ setEnableMobileMenu, setEnableMobileMenuSmall }
)(AppMobileMenu);
